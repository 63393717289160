.top-div {
  margin: 2rem 4rem;
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
  margin: 2rem 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: transparent; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  border-radius: 30px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*-------------------Extra css   */

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}

..it-services-card {
  width: 100%;
  height: 100%;
}
.it-services-card img {
  object-fit: cover;
  width: 100%;
  height: 70vh;
  border-radius: 30px;
  padding: 0.5rem;
}

.it-services-card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 1rem 2rem;
  color: #fff;
  padding: 0.6rem;
  border-radius: 30px;
  background: #fff;
}

.it-services-card-title:hover {
  background: #fff;
  color: #000;
  padding: 0.6rem;
  border-radius: 30px;
  /* background: #f98917; */
}

.it-services-card-description {
  font-size: 1rem;
  color: #666666;
}

.swiper-button-prev,
.swiper-button-next {
  color: #f98917;
}

/*----------------------- Academy Button   */

.it-services-card-image {
  transition: transform 0.3s ease;
}

.it-services-card-image:hover {
  border-radius: 20px !important;
  filter: brightness(0.9);
  transform: scale(1.1);
}

.academy-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  display: flex;
  font-weight: 400;
  font-size: 25px;
  gap: 0.5rem;
  color: #000;
  align-items: center;
}

.academy-button:hover {
  color: #fff;
}

.academy-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: #000;
  height: 2px;
  transition: 0.3s ease-out;
}

.academy-button:hover::after {
  width: 100%;
}

.academy-button:hover p::before {
  width: 100%;
}

.academy-button:hover svg {
  transform: translateX(15px);
  color: #000;
}

.academy-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 20px;
  transition-delay: 0.1s;
}

.academy-button-text {
  font-size: 1.5rem;
  color: #fff;
  background: #1B1B1B;
  padding: 0 2rem;
  border-radius: 20px;
}

@media screen and (max-width: 1200px) {
  .top-div {
    margin: 1rem 1rem;
  }
  /* .it-services-card img {
      width: 70vw;
      height: 38vh;
    } */
  .it-services-card-title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 968px) {
  .top-div {
    margin: 1rem 1rem;
  }
  .it-services-card img {
    object-fit: cover;
    border-radius: 20px;
    width: 80vw;
    height: 35vh;
  }
  .it-services-card-title {
    font-size: 1rem;
  }

  .it-services-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin: 0.8rem 1.5rem;
    color: #fff;
    padding: 0.4rem;
    border-radius: 25px;
    width: 90%;
    background: #fff;
  }
  .academy-button-text {
    font-size: 1.3rem;
    color: #fff;
    background: #1B1B1B;
    padding: 0 0.8rem;
    border-radius: 20px;
  }
}

@media screen and (max-width: 800px) {
  /* .it-services-card img {
    object-fit: cover;
    width: 100vw;
    height: 20rem;
  } */
  .it-services-card-title {
    font-size: 1rem;
  }

  /* .it-services-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.rem;
    margin: 0.6rem 1rem;
    color: #fff;
    padding: 0.3rem;
    border-radius: 20px;
    width: 90%;
    background: #fff;
  }
  */

  .academy-button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    display: flex;
    font-weight: 400;
    font-size: 25px;
    gap: 0.5rem;
    color: #000;
    height: 80%;
    align-items: center;
  }

  .academy-button-text {
    font-size: 1rem;
    color: #fff;
    background: #1B1B1B;
    padding: 0 0.5rem;
    border-radius: 20px;
  }
}

@media screen and (max-width: 568px) {
  .top-div {
    margin: 0.1rem 0.1rem;
  }

  .it-services-card img {
    object-fit: content;
    width: 100vw;
    height: 18rem;
  }
  .it-services-card-title {
    font-size: 0.5rem;
  }

  .swiper {
    margin: 2rem 0;
  }

  .academy-button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    display: flex;
    font-weight: 400;
    font-size: 10px;
    /* gap: 0.5rem; */
    align-items: center;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }

  .academy-button::after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    bottom: -7px;
    background: #f98917;
    height: 1px;
    transition: 0.3s ease-out;
  }

  .academy-button:hover::after {
    width: 100%;
  }

  .academy-button:hover p::before {
    width: 100%;
  }

  .academy-button:hover svg {
    transform: translateX(4px);
    color: #000;
  }

  .academy-button svg {
    color: var(--primary-color);
    transition: 0.2s;
    position: relative;
    width: 10px;
    transition-delay: 0.2s;
  }

  .it-services-card-image {
    object-fit: cover;
    width: 100%rem;
    height: 25rem;
    transition: transform 0.3s ease;
    border-radius: 0px;
  }

  .it-services-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 0.5rem 0.8rem;
    color: #fff;
    padding: 0.2rem;
    border-radius: 20px;
    width: 90%;
    background: #fff;
  }
  .academy-button-text {
    font-size: 1rem;
    color: #fff;
    background: #1B1B1B;
    padding:  0.5rem;
    border-radius: 20px;
  }
}

@media screen and (max-width: 468px) {
  .top-div {
    margin: 0.1rem 0.1rem;
  }

  .swiper {
    margin: 2rem 0;
  }

  .it-services-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 0.5rem 0.8rem;
    color: #fff;

    padding: 0.2rem;
    border-radius: 40px;
    width: 90%;
    background: #fff;
  }
  .academy-button-text {
    font-size: 0.5rem;
    color: #fff;
    background: #1B1B1B;
    padding: 0 0.5rem;
    border-radius: 20px;
  }
}
