@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --swiper-theme-color :#fff !important;
}

.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #474bff;
    background: linear-gradient(currentColor 0 0) center/100% 4.5px,
           linear-gradient(currentColor 0 0) center/4.5px 100%,
           radial-gradient(farthest-side,#0000 calc(100% - 6.7px),currentColor calc(100% - 5.6px)),
           radial-gradient(circle 6.7px,currentColor 94%,#0000 0);
    background-repeat: no-repeat;
    animation: spinner-mu2ebf 1s infinite linear;
    position: relative;
 }
 
 .spinner::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: inherit;
    transform: rotate(45deg);
 }
 
 @keyframes spinner-mu2ebf {
    to {
       transform: rotate(.5turn);
    }
 }

 .bottom-text {
   display: flex;
   gap: 20%;
   justify-content: center;
   align-content: center;
   padding: 10px;
 }